import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <h4><strong parentName="h4">{`*`}{`*`}{`The Barbell Club will be cancelled today because of the weather.  We’ll pick it back up this Saturday at 11:15!`}{`*`}{`*`}</strong></h4>
    <h4><strong parentName="h4">{`Classes will be an hour and a half each and offered Saturday’s (11:15-12:45) and Sunday’s (1:45-3:00) each weekend after our CrossFit classes. You can pay per class `}<em parentName="strong">{`(\\$15 for members, \\$20 for non members)`}</em>{` or pay upfront for a discounted rate for the entire online program plus all classes `}<em parentName="strong">{`(\\$120 for members, \\$200 for couples)`}</em>{`.  Email Daniel for more information or simply show up to class.`}</strong></h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      